@import '../../assets/sass/variables.scss';

.filterBar {
  background: #fff;
  border-bottom: 1px solid #f1f2f3;
  padding: 10px;
}

@mixin section {
  display: grid !important;
  grid-template-columns: 24% 38% 38%;
}
@mixin sectionPart {
  padding: 20px;
  &:not(:first-child) {
    border-left: 1px solid #f1f2f3;
  }
}

.section {
  @include section;
  .sectionLeft,
  .sectionCenter,
  .sectionRight {
    @include sectionPart;
    .sectionHeader {
      color: #00c49f;
      display: block;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      &.colorOne {
        color: #0088fe;
      }
      &.colorTwo {
        color: #ff8042;
      }
      &.colorThree {
        color: #ca80dc;
      }
      &.colorFour {
        color: #5e5cb6;
      }
      &.colorFive {
        color: #148e55;
      }
    }
    .sectionData {
      padding-top: 30px;
      h5 {
        margin-bottom: 4px;
      }
      span {
        font-size: 16px;
      }
    }
    .sectionSubDataGroup {
      display: grid;
      grid-template-columns: 50% 50%;
      .sectionSubData {
        padding-top: 15px;
        h5 {
          color: #808a97;
          font-size: 18px;
          margin-bottom: 4px;
        }
        span {
          color: #808a97;
          font-size: 16px;
        }
      }
    }
  }
  .sectionLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .lastUpdated {
    color: $color-primary-grey;
    display: inline-block;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
    margin-top: 20px;
  }
}

// Loaders et al
@keyframes shine {
  100% {
    transform: translateX(100%);
  }
}

@mixin animatedBar {
  background: $color-border-grey;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  &::after {
    animation: shine 1200ms infinite;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.015),
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.015)
    );
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transform: translateX(-100%);
    width: 100%;
  }
}

.sectionLoader {
  @include section;
  .sectionLeft,
  .sectionCenter,
  .sectionRight {
    @include sectionPart;
  }
  .sectionBody {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sectionHeader {
    @include animatedBar;
    height: 16px;
    width: 35%;
  }
  .sectionData {
    margin-top: 35px;
    &__count {
      @include animatedBar;
      height: 28px;
      width: 60%;
    }
    &__summary {
      @include animatedBar;
      height: 16px;
      width: 85%;
      margin-top: 8px;
      opacity: 0.6;
    }
  }
  .sectionGraph {
    @include animatedBar;
    border-radius: 10px;
    width: 100%;
    height: 220px;
    margin-top: 30px;
    opacity: 0.6;
  }
}

// Utils
:global .recharts-legend-item-text {
  position: relative;
  top: 2px;
}
