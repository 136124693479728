/* make the customizations */
$theme-colors: (
	'primary': #3b54ec,
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

@import './global';
@import './header';
@import './landing';
@import './footer';
@import './utils';

// Plugins
// @import './plugins/revealer';
@import './plugins/preloader';