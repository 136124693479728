/* footer */
.footer {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  border-bottom: 4px solid #3b54ec;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px 0 35px;
}

.footer ul {
  display: flex;
  font-size: 15px;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

.footer li {
  margin-right: 20px;
}

.footer li a {
  color: #9da5b0;
  transition: all ease-in-out 0.3s;
}

.footer li a:hover {
  color: #3b54ec;
  text-decoration: none;
}

.footer-logo img {
  width: 100%;
  max-width: 110px;
}

@media screen and (max-width: 768px) {
  .footer-content {
    display: block;
    text-align: center;
  }
  .footer ul {
    display: block;
    margin: 20px auto 0;
  }
  .footer li {
    margin: 0 0 10px 0;
  }
}