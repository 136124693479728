@import '../../../assets/sass/variables.scss';

.header {
  padding-left: 10px;
  border-bottom: 1px solid rgba(157, 165, 176, 0.1);
  padding-bottom: 11px;
  .org {
    align-items: center;
    border: 1px solid rgba(157, 165, 176, 0.1);
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    padding: 4px;
    width: 70%;
    transition: background 700ms;
    &:hover {
      background-color: rgba(245, 245, 248, 1);
      border-color: rgba(245, 245, 248, 1);
    }
    .avatar {
      background-color: #24a148;
      border-radius: 100px;
      color: #fff;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
    }
    .name {
      font-weight: 500;
      margin-left: 6px;
      display: block;
      width: 100px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      flex-shrink: 0;
      fill: rgba(98, 121, 143, 1);
      margin-left: 4px;
    }
  }
  .user {
    > * {
      color: #808a97;
      display: block;
    }
    .name {
      color: $color-body;
      font-weight: 500;
    }
  }

  :global .dropdown-menu {
    border-radius: 6px;
    font-size: 14px;
    padding: 0;
    overflow: hidden;
    width: 220px;
    left: 0 !important;
    top: 4px !important;
    &[x-placement='top-start']::after,
    &[x-placement='bottom-start']::after {
      background-image: none;
    }

    .dropdown-header {
      padding: 10px 15px;
    }

    .dropdown-divider {
      border-color: $color-primary-grey;
      margin: 0;
      opacity: 0.1;
    }

    .dropdown-item {
      color: $color-body;
      padding: 10px 15px;
      transition: all 400ms;
      svg {
        margin-right: 4px;
      }
      &:hover {
        background-color: rgba(245, 245, 248, 1);
      }
    }
  }
}
