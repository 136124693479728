@import '../../../assets/sass/variables.scss';

.sidebar {
  background: #fff;
  min-width: $sidebar-width;
  width: $sidebar-width;
  height: 100vh;
  margin-left: -$sidebar-width;
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px 0 0;
  overflow: hidden;
  border: 1px solid $color-border;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.01);
  transition: all 400ms ease-in-out;

  &:not(.mini) {
    z-index: 10;
  }

  &.open {
    margin-left: 0;
    z-index: 10;
  }

  .closeIcon {
    background: rgba(245, 245, 248, 1);
    border-radius: 4px;
    color: rgba(98, 121, 143, 1);
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
    padding: 4px 8px;
    transform: translateX(0);
    transition: all 300ms;
    z-index: 10;
    &:hover {
      background: rgba(98, 121, 143, 0.1);
      transform: translateX(-6px);
    }
  }

  :global .nav {
    flex-wrap: nowrap;
    overflow-y: auto;
    height: calc(100% - 50px);
  }
  :global .nav-link {
    color: #0c1326;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0.3rem 1.4rem;
    transition: all 700ms;
    > * {
      transition: all 400ms ease-in-out;
    }
    svg {
      width: 16px;
      height: 16px;
      stroke: rgba(157, 165, 176, 0.7);
      margin-right: 6px;
    }
    span {
      position: relative;
    }
    &:hover {
      background: rgba(245, 245, 248, 1);
    }
    &.active {
      color: $color-primary;
      svg {
        stroke: $color-primary;
      }
    }
    &.danger {
      color: $color-danger;
      svg {
        fill: $color-danger;
      }
    }
  }
}

.sidebar.mini {
  min-width: $sidebar-mini-width;
  width: $sidebar-mini-width;
  margin-left: -$sidebar-mini-width;
  padding-top: 15px;
  transition: all 400ms ease-in-out;
  transition-delay: 350ms;

  &.open {
    margin-left: 0;
  }

  .header {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .logo {
      width: 30px;
    }
  }
  :global .nav-link {
    margin: 10px 0;
    padding: 8px;
    transition: all 400ms ease-in-out;
    svg {
      stroke: #9da5b0;
      width: 24px;
      height: 24px;
      margin: 0 auto;
    }
    &.active,
    &:hover {
      background: rgba(59, 84, 236, 0.1);
      svg {
        stroke: $color-primary;
      }
    }
  }
}

.sectionTitle {
  color: #808A97;
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  padding: 1rem 0 0.2rem 1.4rem;
  text-transform: uppercase;
}
