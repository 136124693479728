.spinner {
  background-image: url(../../images/icons/icon-spinner.svg);
  background-repeat: no-repeat;
  background-size: 36px;
  width: 36px;
  height: 36px;
  display: block;
  margin-bottom: 0;
  background-position: center;
  &.small {
    width: 24px;
    height: 24px;
    background-size: 24px;
  }
  &.button {
    width: 24px;
    height: 24px;
    background-size: 24px;
    position: relative;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
  }
  &.primary {
    background-image: url(../../images/icons/icon-spinner-primary.svg);
  }
  &.white {
    background-image: url(../../images/icons/icon-spinner-white.svg);
  }
}
