/**
 * Fonts, base styles and layout stuff
 */

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url(../fonts/Inter-Regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url(../fonts/Inter-SemiBold.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(../fonts/Inter-Bold.woff2);
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  background: #f5f5f8;
  padding: 0;
  margin: 0;
  font-weight: normal;
  color: $color-body;
  font-family: $brand-font;
  font-size: 14px;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// ::-webkit-scrollbar {
//   // width: 20px; /* Remove scrollbar space */
//   // height: 20px;
//   // background: transparent; /* Optional: just make scrollbar invisible */
//   // opacity: 0.5;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0c1326;
  font-family: $brand-font;
  font-weight: 700;
  line-height: 1.2;
}

a {
  color: $color-link;
  &:hover {
    text-decoration: none;
  }
}

hr {
  border-color: #f1f2f3;
}

small,
.small {
  font-size: 90%;
}

form {
  .col,
  .col-md-12 {
    padding: 0;
  }
  .col-md-3,
  .col-md-4 {
    padding-left: 0;
  }
  .col-md-8,
  .col-md-9 {
    padding-right: 0;
  }
}

.app {
  background: #f5f5f8;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar {
  background: #fff;
  border-bottom: 1px solid #f1f2f3;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.01);
}

.navbar-brand {
  font-weight: 500;
  font-size: 18px;
}

.page-wrapper {
  width: 100%;
  margin: 0;
  transition: all 400ms ease-in-out;
  transition-delay: 100ms;
  &.with-sidebar {
    width: calc(100% - #{$sidebar-width});
    margin-left: $sidebar-width;
  }
  &.with-mini-sidebar {
    width: calc(100% - #{$sidebar-mini-width});
    margin-left: $sidebar-mini-width;
  }
}

.page-container {
  margin: 20px 10px 40px;
}

@media (max-width: 768px) {
  .page-wrapper {
    width: 100%;
    margin: 0;
  }
}
