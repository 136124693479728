@import '../variables.scss';

// report grid
.report-grid {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: 1;
  grid-template-areas: 'main' 'side';
  position: relative;
  width: 100%;
  min-height: 100%;
  .report-grid__main {
    grid-area: main;
    border-right: 1px solid #f1f2f3;
    padding: 20px 20px 10px;
  }
  .report-grid__side {
    border-bottom: 1px solid #f1f2f3;
    grid-area: side;
    grid-row: 1;
    grid-column: 2;
    padding: 20px 20px 10px;
    max-height: 400px;
    overflow-y: auto;
  }
}

// badges
.badge {
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
}

.badge:not(.flag) {
  &.badge-success {
    background-color: rgba(36, 161, 72, 0.1);
    color: $color-success;
  }
  &.badge-danger {
    background-color: rgba(218, 30, 40, 0.1);
    color: $color-danger;
  }
  &.badge-warning {
    background-color: #fff3cd;
    color: #856404;
  }
}

.badge.flag {
  padding-left: 22px;
  position: relative;
  background-color: transparent;
  border: 1px solid $color-border-grey;
  color: $color-body;
  text-transform: capitalize;
  &:before {
    background-color: $color-accent;
    border-radius: 10px;
    content: ' ';
    display: block;
    position: absolute;
    left: 6px;
    top: 6px;
    margin-right: 8px;
    height: 10px;
    width: 10px;
  }
  &--store {
    &:before {
      background-color: #ca80dc;
    }
  }
  &--technical {
    &:before {
      background-color: #9da5b0;
    }
  }
  &--quality {
    &:before {
      background-color: #71bef4;
    }
  }
  &--delivery {
    &:before {
      background-color: #b2586e;
    }
  }
  &--complaints,
  &--shopper {
    &:before {
      background-color: #5e5cb6;
    }
  }
  &--others,
  &--merchandiser {
    &:before {
      background-color: #ffb27a;
    }
  }
}

// pagination
.pagination {
  justify-content: center;
  .page-item {
    margin: 0 6px;
    .page-link {
      font-family: $system-font;
      font-weight: 500;
      text-align: center;
      border-radius: 6px;
      color: $color-body;
      padding-bottom: 0.6rem;
      transition: all 400ms ease;
      &:hover,
      &:focus {
        color: $color-primary;
        background-color: rgba($color-primary, 0.1);
        border-color: rgba($color-primary, 0.1);
        box-shadow: none;
      }
    }
    &.active .page-link {
      color: #fff;
      background-color: $color-primary;
      border-color: $color-primary;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
}

// utils
.w-auto {
  width: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.action-link {
  font-size: 90%;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-link;
  cursor: pointer;
  &.grey {
    color: $color-primary-grey;
  }
  &:hover {
    color: $color-primary;
  }
}

.text-muted {
  color: #808a97 !important;
}

.text-currency {
  font-family: $system-font;
}

.text-truncated {
  display: block;
  width: 250px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-small-title {
  color: $color-primary-grey;
  display: block;
  font-size: 90%;
  font-weight: 500;
  text-transform: uppercase;
}

.font-weight-medium {
  font-weight: 500;
}

.hidden-input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.sticky-top {
  z-index: 7;
}
