/** 
 * List group styles
 */

.list-group {
  background: #fff;
  border: 1px solid $color-border;
  border-radius: 6px;
  height: 100%;
  .list-group-body {
    border: 0;
    height: 100%;
  }
  &.compressed {
    .list-group-body {
      height: 0;
      overflow-y: hidden;
    }
  }
  .toggle-icon {
    background: rgba(59, 84, 236, 0.1);
    border: 1px solid rgba(59, 84, 236, 0.1);
    border-radius: 4px;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    color: $color-primary;
    padding: 2px 6px;
    overflow-x: hidden;
    transition: all 300ms;
    svg {
      transition: all 400ms;
    }
    .toggle-text {
      &:before {
        content: 'Expand';
        display: inline-block;
        margin-left: 6px;
        width: 52px;
        transition: all 300ms;
      }
    }
    &:hover {
      background: rgba(59, 84, 236, 0.15);
    }
    &.expanded {
      background: rgba(59, 84, 236, 0.1);
      color: $color-primary;
      svg {
        transform: rotate(180deg);
      }
      .toggle-text {
        &::before {
          content: '';
          margin: 0;
          width: 0px;
        }
      }
    }
    &.expanded:hover {
      svg {
        transform: rotate(180deg);
      }
      background: rgba(59, 84, 236, 0.15);
    }
  }
}

.list-group-item {
  border-color: #f1f2f3;
  border-width: 1px 0 1px 0;
  &.first,
  &.last {
    border-width: 0;
  }
}
