@import '../variables.scss';

/** 
 * Table styles
 */

.table {
  margin-bottom: 0;
}

.table-wrapper {
  .table th {
    border-top: 0;
  }
  .table th:first-child,
  .table td:first-child {
    border-left: 0;
  }
  &.bordered {
    > div {
      border: 1px solid $color-border-grey;
    }
    .table th {
      border-top: 0;
    }
    .table tr:last-child td {
      border-bottom: 0;
    }
  }
  &.padded {
    > div {
      margin-bottom: 10px;
    }
    .table thead th {
      padding-left: 20px;
      padding-right: 20px;
    }
    .table tbody td {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.table thead {
  th {
    background: #f9f9fb !important; // #f9f9fb
    border-bottom: 0;
    color: rgba(7, 11, 31, 0.5);
    font-weight: 500;
    font-size: 14px !important;
    transition: background 300ms ease;
    svg {
      fill: $color-primary-grey;
      opacity: 0.4;
      margin-left: 8px;
      position: relative;
      top: -1px;
      transition: fill 300ms, opacity 400ms;
      &.active {
        fill: $color-body;
        opacity: 0.9;
      }
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }

    &.active,
    &.sortable {
      cursor: pointer;
      &:hover {
        background: rgba(244, 244, 247, 0.8) !important; // #f4f4f7
      }
    }
    &.active {
      color: $color-body !important;
    }
  }
  tr.main th {
    color: $color-body;
  }
}

.table tbody td {
  padding-top: 14px;
  padding-bottom: 11px;
}

.table thead th,
.table tbody td {
  border-left: 0;
  border-bottom: 1px;
  border-color: #f1f2f3 !important;
  font-size: 14px;
  &.hidden {
    color: transparent !important;
  }
  &:last-child {
    border-right: 0;
  }
}

.table-bordered thead th,
.table-bordered tbody td {
  border: 1px solid;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f9f9fb;
}

.table-hover tbody tr:hover {
  background-color: #f9f9fb;
  td {
    background-color: #f9f9fb;
  }
}

// table-fit
@mixin table-fit-width {
  width: auto;
}

.table-fit {
  @include table-fit-width;
  table-layout: auto;
  th {
    @include table-fit-width;
  }
  td {
    @include table-fit-width;
  }
  td,
  th {
    font-size: 14px;
    white-space: nowrap;
    width: 1%;
  }
}

// table color codes
td {
  &.good {
    color: #fff;
    background: rgba(36, 161, 72, 1) !important;
  }
  &.low {
    color: #fff;
    background: rgba(36, 161, 72, 0.7) !important;
  }
  &.very-low {
    color: #fff;
    background: rgba(249, 177, 61, 1) !important;
  }
  &.critically-low {
    color: #fff;
    background: rgba(218, 30, 40, 0.7) !important;
  }
  &.zero {
    color: #fff;
    background: rgba(218, 30, 40, 1) !important;
  }
}

// sticky cells
th.sticky,
td.sticky {
  background: #fff;
  position: sticky;
  left: 0px;
  // z-index: 1;
}
th.sticky {
  background: #f9f9fb;
}

td.sticky span,
td.sticky > *,
td.truncate span {
  display: block;
  width: 250px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td.wrap span {
  display: block;
  width: 250px;
  white-space: normal;
}
