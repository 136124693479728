// nav-pills
.nav-pills .nav-link {
  color: #0C1326;
  display: block;
  font-weight: 500;
  padding: 0.4rem 0.75rem 0.25rem;
  &:focus {
    outline: none;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #3b54ec;
  background-color: rgba(59, 84, 236, 0.1);
}

.nav.card-header-pills.nav-pills {
  overflow: scroll;
  flex-wrap: nowrap;
  white-space: nowrap;
}

// nav-tabs
.nav-tabs {
  border: 0;
  flex-wrap: nowrap;
  .nav-link {
    border: 0;
    border-bottom: 1px solid #f1f2f3;
    color: $color-primary-grey;
    font-size: 13px;
    font-weight: 500;
    padding: 15px 10px 4px !important;
    width: 100%;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &.active {
      border-color: $color-primary;
      border-width: 1px;
      color: $color-primary;
      opacity: 1;
    }
  }
}
