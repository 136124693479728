#landing {
  .navbar-container {
    background: #f5f7fa;
    border: 1px solid #e7e8ef;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    transition: all ease-in-out 0.3s;
  }

  .navbar {
    border-radius: 10px;
    font-size: 15px;
    margin: 10px auto;
    max-width: 1140px;
    padding: 20px 15px;
    transition: all ease-in-out 0.3s;
  }
  .navbar .navbar-logo {
    display: block;
  }
  .navbar .navbar-logo-light {
    display: block;
  }

  .navbar--open {
    background: #fcfcfe;
  }
  .navbar--open .navbar-logo {
    display: block;
  }
  .navbar--open .navbar-logo-light {
    display: none;
  }

  .navbar .navbar-brand {
    position: relative;
    top: -1px;
  }

  .navbar .navbar-brand img {
    max-width: 110px;
    width: 100%;
  }

  .navbar-scrolling {
    /* background: #fcfcfe; */
    /* box-shadow: 0 10px 10px 10px rgba(33, 43, 55, 0.04); */
    transform: translateY(-100%);
  }

  .navbar-nav {
    transition: all ease-in-out 0.3s;
  }

  .navbar-nav .nav-link {
    color: #070b1f;
    font-weight: 500;
    transition: all ease-in-out 0.5s;
  }

  .navbar-nav .nav-link:hover {
    color: #3b54ec;
  }

  @media (max-width: 768px) {
    .navbar {
      padding: 20px !important;
    }
    .navbar-nav .nav-link {
      font-size: 18px;
      margin-left: 30px;
      margin-top: 15px;
      padding: 0.3rem 0;
    }
  }

  .hamburger {
    border: none;
    width: 20px;
    height: 18px;
    padding: 0;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  .hamburger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #3b54ec;
    border-radius: 0;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  .hamburger span:nth-child(1) {
    top: 0px;
  }

  .hamburger span:nth-child(2) {
    top: 8px;
  }

  .hamburger span:nth-child(3) {
    top: 16px;
  }

  .hamburger.open span:nth-child(1) {
    top: 8px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .hamburger.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  .hamburger.open span:nth-child(3) {
    top: 8px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
}
