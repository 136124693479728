@import '../../../assets/sass/variables.scss';

.header {
  position: fixed;
  width: calc(100% - #{$sidebar-width});
  top: 0;
  z-index: 8;
  transition: all 400ms;

  &.withMiniSidebar {
    width: calc(100% - #{$sidebar-mini-width});
  }

  :global .navbar {
    height: 60px;
    box-shadow: none;
    border-bottom: 1px solid $color-border;
  }
}

.pad {
  height: 60px; // Support fixed header
}

h6.title {
  margin-top: 2px;
  font-weight: 500;
  margin-bottom: 0;
  &.link {
    color: #0c1326;
    cursor: pointer;
    transition: color 300ms;
    &:hover {
      color: $color-primary;
    }
  }
  &.hasBack {
    color: #808A97;
  }
}

.breadcrumb {
  display: none;
  overflow: hidden;
  align-items: center;
  transition: all 300ms;
  transition-delay: 500ms;
  &.open {
    display: flex;
  }
  svg {
    position: relative;
    margin: 0 5px;
  }
}

.notifications {
  cursor: pointer;
  position: relative;
  .notificationsBadge {
    background-color: $color-danger;
    border-radius: 100px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    font-family: $system-font;
    width: 22px;
    height: 22px;
    position: absolute;
    top: -10px;
    right: -7px;
    padding: 4px 5px;
    margin-right: 8px;
    transition: all 400ms;
  }
}
