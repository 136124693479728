.cursor-pointer {
  cursor: pointer !important;
}

.text-primary {
  color: #3b54ec;
}

.text-medium {
  font-weight: 500;
}