/* hero */
.hero {
  background-color: #F5F7FA;
  // background-image: url(../images/hero-bg-dark.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 140px 0 0;
  position: relative;
  overflow: hidden;
}

.hero .container {
  position: relative;
}

.hero__title {
  font-size: 60px;
  line-height: 70px;
}

.hero__title span {
  color: #3b54ec;
}

.hero__subtitle {
  font-size: 18px;
  line-height: 30px;
}

.hero__image {
  // max-width: 400px;
  margin: 0 auto;
}
.hero__image img {
  width: 100%;
}

// @media only screen and (min-width: 768px) and (max-width: 1200px) {
//   .hero__title {
//     font-size: 44px;
//     line-height: 54px;
//   }
// }

@media (max-width: 768px) {
  .hero {
    text-align: center;
    padding: 160px 0 0;
    max-height: 1010px;
  }
  .hero__title {
    font-size: 40px;
    line-height: 48px;
  }
  .hero__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 60px;
  }
}