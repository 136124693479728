/** 
 * Modal styles
 */

.modal-backdrop {
  z-index: 100;
}
.modal-dialog {
  max-width: 540px;
  z-index: 101;
  .modal-content {
    border: 0;
    border-radius: 6px;
    z-index: 101;
  }
  .modal-header {
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #f1f2f3;
    border-radius: 12px 12px 0 0 !important;
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .modal-title {
    font-size: 16px;
    font-weight: 500;
    margin: 4px 0 0;
  }
  .modal-body {
    overflow-y: scroll;
  }
}
