/** 
 * Form and button styles
 */

label {
  font-size: 14px !important;
  margin-bottom: 0;
}

.custom-control:not(.custom-switch) {
  .custom-control-label {
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 5px;
    &::before,
    &::after {
      top: 2px;
    }
    &::before {
      border: 1px solid #cfd6e4;
    }
  }
}

.custom-control-input {
  &:checked {
    ~ .custom-control-label::before {
      border-color: #3b54ec;
    }
  }
}

.custom-checkbox {
  min-height: auto;
  .custom-control-label {
    &::before {
      border-radius: 0.15rem !important;
    }
    &::after {
      left: -1.5rem;
    }
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label::before {
        background: #3b54ec;
        border-color: #3b54ec;
      }
      ~ .custom-control-label::after {
        background-image: url(../../images/icons/checkbox-checked.svg);
      }
    }
    &[data-indeterminate='true'] {
      ~ .custom-control-label::before {
        background: linear-gradient(180deg, #f5f6f7 50%, #f5f6f7 100%), #ffffff;
        box-shadow: inset 0px -1px 1px rgba(67, 90, 111, 0.04);
      }
      ~ .custom-control-label::after {
        background-image: url(../../images/icons/checkbox-indeterminate.svg);
      }
    }
    &:disabled:checked ~ .custom-control-label::before {
      background-color: $color-primary;
      opacity: 0.4;
    }
  }
}

.btn {
  font-size: 14px;
  font-weight: 500;
  height: 42px;
  padding: 8px 13px;
  line-height: 0;
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  svg {
    margin: -2px 2px 0 0;
  }
}

.btn-link {
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.btn-sm,
.btn-group-sm > .btn {
  height: 34px;
  padding: 5px 10px;
  border-radius: 6px;
  line-height: 0;
}

.btn-outline-light {
  color: $color-body !important;
  background: #fff; // rgba(249, 249, 251, 1);
  border: 1px solid rgba(0, 0, 0, 0.08);
  &.active,
  &:hover {
    background: rgba($color-primary, 0.1) !important;
    border: 1px solid rgba($color-primary, 0.1) !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    color: $color-primary !important;
  }
}

.form-text {
  margin-top: 0;
}

.invalid-feedback {
  display: block;
  font-size: 90%;
}

.form-group {
  margin: 30px 0;
}

// .custom-control-input:checked ~ .custom-control-label::before {
//   border-color: $color-success;
//   background-color: $color-success;
// }

.form-control {
  border-radius: 6px;
  font-size: 14px;
  height: 42px;
  border-color: #e7e8ef;
  padding-bottom: 0.45rem;
  &:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid $color-primary;
  }
}

.input-group {
  width: auto;
  flex-wrap: nowrap;
  .react-datepicker-wrapper:first-child .form-control {
    border-radius: 4px 0 0 4px;
  }
  .react-datepicker-wrapper:last-child .form-control {
    border-left: 0;
    border-radius: 0 4px 4px 0;
  }
}

.input-group-text {
  font-size: 14px;
  padding-bottom: 0.2rem;
  border-color: #e7e8ef;
}

.form-control-sm {
  border-radius: 4px;
  height: 34px;
  padding-bottom: 0.35rem;
}

.form-control-sm.date {
  width: 100px;
}

select {
  &.form-control {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(../../images/icons/caret.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
    background-size: 12px;
    padding-right: 30px !important;
  }
  &.form-control-sm {
    padding-bottom: 0.35rem;
  }
}

.search.form-control {
  background-image: url(../../images/icons/search.svg);
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 16px;
  padding-left: 30px !important;
}
