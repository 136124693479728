@import './variables';

// Bootstrap customizations
$body-color: $color-body;
$theme-colors: (
  'primary': $color-primary,
  'success': $color-success,
  'danger': $color-danger,
);
$grid-gutter-width: 15px;
$container-max-widths: (
  xs: 540px,
  sm: 700px,
  md: 960px,
  lg: 1140px,
  xl: 1300px,
  g: 1560px,
  xg: 1920px,
) !default;

// import bootstrap to set changes
@import '~bootstrap/scss/bootstrap';

@import './base';

// Global components
@import './components/card';
@import './components/filters.scss';
@import './components/form';
@import './components/list-group';
@import './components/modal';
@import './components/table';
@import './components/tabs';

// Utils
@import './utils/spinner.scss';
@import './utils/utils.scss';

// Plugins
@import './plugins/datepicker';
