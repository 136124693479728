/** 
 * Card styles
 */

.card {
  background: #fff;
  border: 1px solid $color-border;
  border-radius: 6px;
  transition: all 100ms ease-in-out;
  &.centered {
    margin: 0 auto;
    max-width: 600px;
  }
}

.card-header {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f1f2f3;
  border-radius: 8px 8px 0 0 !important;
  display: flex;
  justify-content: space-between;
  &.odd,
  &.filters {
    background: #f9f9fb;
    border-radius: 0 !important;
    padding-top: 12px;
    flex-wrap: nowrap;
  }
}

.card-title {
  font-size: 14px;
  font-weight: 500;
  margin: 2px 0 0;
  color: $color-body;
}

.card-body {
  position: relative;
  &::after {
    content: '';
    background: rgba(255, 255, 255, 1);
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    visibility: hidden;
  }
  &.disabled::after {
    opacity: 0.7;
    visibility: visible;
  }
  &.contained {
    max-height: 90vh;
    position: relative;
    overflow-y: auto;
  }
}

.card-footer {
  background: #fff;
  border-top: 1px solid #f6f6f6;
  border-radius: 0 0 8px 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
