@import '../../../assets//sass/variables';

.forumImage {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  // border: 1px solid $color-border-grey;
  border-radius: 12px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}
.forumImage:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.forumImage:hover .forumImage__delete {
  opacity: 1;
  transform: scale(1);
}

.forumImage__meta {
  background: #fff;
  border-radius: 10px;
  bottom: 0;
  clip-path: inset(6px round 10px 10px 10px 10px);
  font-size: 14px;
  padding: 20px;
  position: absolute;
  width: 100%;
  .small {
    line-height: 16px;
  }
}
