.dropdown-toggle {
  &.btn-link {
    padding-left: 0;
    padding-right: 0;
  }
  svg {
    margin: 0 4px 0 0;
  }
  &::after {
    display: none;
  }
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
}

.dropdown-menu {
  border: 1px solid #f1f2f3;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  padding: 15px 20px;
  width: 300px;
  &.wide-5x {
    width: 550px;
  }
  &[x-placement='top-start']::after,
  &[x-placement='bottom-start']::after {
    content: '';
    position: absolute;
    display: block;
    width: 37px;
    height: 12px;
    top: -12px;
    left: 8%;
    right: 0;
    background-image: url('../../images/icons/dropdown_arrow.png');
    background-size: 100% 100%;
  }
  &[x-placement='top-start']::after {
    top: auto;
    bottom: -12px;
    transform: rotate(180deg);
  }
  .input-group {
    .form-control-sm.date {
      width: 100%;
    }
  }
}

.dropdown-menu-right {
  &::after {
    left: auto;
    right: 8%;
  }
}

.filters-wrapper {
  .dropdown-menu {
    .filter-group {
      margin: 15px 0;
    }
    label,
    .filter-label {
      display: block;
      font-size: 95%;
      font-weight: 500;
      margin-bottom: 2px;
    }
  }
}
